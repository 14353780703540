import { Box, Container, Grid, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GiDungeonGate, GiTabletopPlayers, GiWorld } from 'react-icons/gi';

import SEO from 'components/atoms/SEO';
import { StarBackground } from 'components/atoms/StarBackground';
import Layout from 'components/templates/Layout';

const StyledLogo = styled('img')`
  user-select: none;
  pointer-events: none;
`;

const Home = () => (
  <Layout>
    <SEO
      {...{
        title: 'Be the master of realms and worlds, breathe life into your stories and imagination.',
        description:
          // eslint-disable-next-line max-len
          'World master RPG is a project aimed at augmenting table top RPG games by giving game masters and players a chance to bring their imagination to life in a virtual environment and make management of their creations easier.',
      }}
    />
    <Box sx={{ position: 'relative', minHeight: '100%' }}>
      <StarBackground />

      <Container
        maxWidth="lg"
        sx={{
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            textAlign: 'center',
            mx: 1,
          }}
        >
          <Box sx={{ my: 'auto', p: 5 }}>
            <Box mb={5}>
              <Box
                sx={{
                  height: '50vh',
                  textAlign: 'center',
                  margin: 'auto',
                }}
              >
                <StyledLogo
                  src="https://storage.googleapis.com/wm-cdn/Logo/2x/WM_logo_coloured%402x.png"
                  alt="logo"
                  height="100%"
                />
              </Box>
              <Typography
                variant="h2"
                className="text-white font-semibold text-5xl  md:whitespace-nowrap text-center"
              >
                Your story starts with us
              </Typography>
              <Typography
                mt={3}
                mx="auto"
                variant="subtitle1"
                fontWeight="200"
                textAlign="center"
                maxWidth="450px"
              >
                Be the master of realms and worlds, breathe life into your stories and imagination.
              </Typography>
            </Box>
            <Grid container spacing={8}>
              <Grid item xs={12} md={4}>
                <GiTabletopPlayers size={45} />
                <Typography mt={2} textAlign="center" sx={{ maxWidth: { md: '400px' } }}>
                  Join the community of numerous game masters, share your created maps, pick your favorites and
                  roll them out during gameplay!
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <GiDungeonGate size={45} />
                <Typography mt={2} textAlign="center" sx={{ maxWidth: { md: '400px' } }}>
                  Delve into the collection of tools designed to help you write your story and keep your timelines
                  straight!
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <GiWorld size={45} />
                <Typography mt={2} textAlign="center" sx={{ maxWidth: { md: '400px' } }}>
                  With players using world master, there will be no more hastle of managing their stats or reading
                  up their backgrounds.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  </Layout>
);

export default Home;
